<template>
  <div class="box">
    <div class="left-content">
      <el-tree
        :data="data"
        :props="defaultProps"
				node-key="doorcode"
				:default-expanded-keys="['1']"
        @node-click="handleNodeClick"
      ></el-tree>
    </div>
    <div style="width: 2%"></div>
    <div class="right-content">
      <el-form
        class="search-box"
        :model="searchlist"
        label-width="150px"
        ref="searchlist"
      >
        <!-- v-for循环截取循环的数组-->
        <el-form-item
          label-width="80px"
          v-for="item in searchType"
          :key="searchType.indexOf(item)"
          :label="item.title"
        >
          <el-input
            style="width: 200px !important"
            :placeholder="'请输入' + item.title + '查询'"
            v-model="searchlist[item.formName]"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" @click="search">
            <i class="el-icon-search" style="font-size: 14px; color: white"></i
            >搜索
          </el-button>
        </el-form-item>
      </el-form>
      <p class="open-text">开锁记录</p>
      <div>
        <el-table
          :data="tableData"
          border
          style="width: 100%; margin-top: 20px"
          stripe
        >
          <el-table-column
            v-for="item in formtype.slice(0, 7)"
            :prop="item.formvalue"
            show-overflow-tooltip
            :label="item.name"
            align="center"
            :key="formtype.indexOf(item)"
          >
          </el-table-column>
          <el-table-column
            v-for="item in formtype.slice(7, 8)"
            :prop="item.formvalue"
            show-overflow-tooltip
            :label="item.name"
            align="center"
            :key="formtype.indexOf(item)"
          >
            <template slot-scope="scope">
              <span v-show="scope.row.openflag == '0'">开门成功</span>
              <el-tooltip
                v-show="scope.row.openflag != '0'"
                class="item"
                effect="dark"
                :content="scope.row.retumsg"
                placement="top-start"
              >
                <span>开门失败</span>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="fenyekuang">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="currentPage"
          :page-sizes="fenye.pagesizes"
          :page-size="fenye.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="fenye.totalnum"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { openDoorList, logQueryPage } from '@/api/entranceguard'
export default {
  mounted() {
    this.getList();
  },
  computed: {},
  data() {
    return {
      tableData: [], //获取的表格数据
      formDialog: false, //弹出框
      formtype: [
        { name: "序号", formvalue: "doorpersonid" },
        { name: "编码", formvalue: "doorcode" },
        { name: "姓名", formvalue: "username" },
        { name: "手机号", formvalue: "phone" },
        { name: "门禁名称", formvalue: "valuebane" },
        { name: "门禁别名", formvalue: "des" },
        { name: "开门时间", formvalue: "systime" },
        { name: "开门状态", formvalue: "openflag" },
      ], //表单须填数据模板
      searchlist: {
        doorcode: "",
        name: "",
        phone: "",
      }, //搜索数据
      searchType: [
        { title: "姓名", formName: "name" },
        { title: "手机号", formName: "phone" },
      ], //搜索框成员
      currentPage: 1, //当前页
      fenye: {
        pagesizes: [10, 20, 50],
        pagesize: 10,
        totalnum: 0,
      }, //分页数据
      data: [
        {
					doorcode: '1',
          label: "全部",
          children: [],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  methods: {
    handleNodeClick(data) {
      if(data.doorcode == 1) return;
      this.searchlist.doorcode = data.doorcode;
      this.getList();
    },
    getList() {

			openDoorList({
				userid: localStorage.getItem("userid"),
        querytyp: 2
			}).then(res=>{
				if (res.result == 200) {
          this.data[0].children = [];
          res.detail.map((item) => {
            this.data[0].children.push({
              label: item.valuebane + "(" + item.openlognums + ")",
              doorcode: item.doorcode,
            });
          });
        } else {
          this.$message.error(res.detail.description);
        }
			})

			logQueryPage({
				userid: localStorage.getItem("userid"),
				doorcode: this.searchlist.doorcode,
				name: this.searchlist.name,
				phone: this.searchlist.phone,
				page: this.currentPage,
				pagesize: this.fenye.pagesize,
			}).then(res=>{
				if (res.result == 200) {
          this.tableData = res.detail.list;
          this.fenye.totalnum = res.detail.totalRow;
        } else {
          this.$message.error(res.detail.description);
        }
			})

			
    }, //获取表格数据和查询请求
    search() {
      this.currentPage = 1;
      this.getList();
    }, //搜索请求
    sizeChange(val) {
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    }, //展示条数改变时
    currentChange(val) {
      this.currentPage = val;
      this.getList();
    }, //当前页改变时
    closeDialog() {
      this.formDialog = false;
    }, //关闭表格
  },
};
</script>
<style lang='scss'>
.box {
  display: flex;
  .left-content {
    width: 15%;
    .el-tree {
      background-color: transparent;
    } //背景色
    .el-tree-node__content {
      height: 50px;
    } //行高
  }
  .right-content {
    width: 80%;
    .search-box {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 10px;
      box-sizing: border-box;
    }
    .open-text {
      height: 30px;
      font-size: 24px;
      font-weight: 600;
      color: skyblue;
      border-left: 5px solid skyblue;
      padding-left: 5px;
    }
    .fenyekuang {
      text-align: right;
      margin-top: 20px;
    }
  }
}
</style>


